.logo {
    > span {
        margin: 0 auto;
    }
}

.newUserModal {
    .section {
        @apply pb-3;

        &:first-child {
            @apply pt-3;
        }
    }

    .subTitleSection {
        .subTitleSpendLimit {
            @apply relative inline-block pr-1;

            &::before {
                content: "";

                @apply absolute bottom-1 h-2 left-0 w-full bg-white;
            }
        }
    }
}
