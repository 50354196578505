@import "styles/arcade";

.footer {
    @apply border-t pt-16;

    @screen md {
        @apply pt-12;
    }

    @screen sm {
        @apply mb-16 pt-4;
    }
}

.col {
    @apply row-start-1 col-span-2 text-sm;

    & li {
        @extend %arcadeFooter2;
        @screen sm {
            @extend %arcadeMobileFooter2;
        }
    }

    @screen md {
        @apply row-start-auto col-span-4;
    }

    @screen sm {
        @apply row-start-auto col-span-4 mb-3;
    }
}

.localeSwitcher {
    @apply row-start-1 col-span-2 text-sm;

    @screen md {
        @apply row-start-auto col-start-7 col-span-2;
    }

    @screen sm {
        @apply row-start-auto col-span-4;
    }
}

.colSmall {
    @apply row-start-2 col-span-2 text-xs;

    @screen md {
        @apply row-start-auto col-span-4;
    }

    @screen sm {
        @apply row-start-auto col-span-4;
    }
}

.colHeader {
    @extend %arcadeFooter1;
    @apply mb-4;
    @screen sm {
        @extend %arcadeMobileFooter1;
    }
}

.legalText {
    @extend %arcadeFooter2;
    @apply text-gray-13 row-start-2 col-span-4 text-xs;

    a {
        @apply underline text-initial;
    }
    @screen md {
        @apply row-start-auto col-span-8;
    }

    @screen sm {
        @extend %arcadeMobileFooter2;
        @apply row-start-auto col-span-4;
    }
}

.acknowledgementText {
    @apply row-start-auto col-span-full text-xs mt-4;
}

.copyright {
    @apply row-start-4 w-32 text-gray-13;
    @extend %arcadeFooter2;
    .copyrightText {
        @apply mt-3;

        @screen sm {
            @apply mt-0;
        }
    }

    @screen md {
        @apply row-start-auto;
    }

    @screen sm {
        @extend %arcadeMobileFooter2;
        @apply row-start-auto;
    }
}

.logo {
    @apply row-start-4 w-full mb-10;
    grid-column: -3 / -1;
    & > span {
        @apply justify-self-end;
        @screen md {
            @apply justify-self-start;
        }
        @screen sm {
            @apply justify-self-start;
        }
    }

    @screen md {
        grid-column-start: 1;
        @apply row-start-auto mb-0;
    }

    @screen sm {
        grid-column-start: 1;
        @apply row-start-auto mb-0;
    }
}

.languageLabel {
    @apply row-start-3 col-span-2;

    @screen md {
        @apply hidden;
    }

    @screen sm {
        @apply hidden;
    }
}

.languageLabelMobile {
    @apply hidden;
    @screen md {
        @apply block;
        @apply row-start-1 col-span-2;
    }

    @screen sm {
        @apply block;
        @apply row-start-1 col-span-2;
    }
}