%flexBase {
    @apply flex items-center;
}

%flexChildContainerBase {
    @apply flex-1;
}

.navWrapper {
    @apply fixed w-full z-10 max-w-app pr-30 mt-4;

    @screen md {
        @apply pr-24 pt-4 mt-0;
    }
    @screen sm {
        @apply pr-4 pt-2 z-2 mt-0;

    }

    &HeaderOnly {
        @apply block;

        @screen md {
            @apply pt-4 pb-12;
        }

        @screen sm {
            @apply pt-2 pb-4;
        }
    }
}

.navBorder {
    @apply border-0-1 border-black p-2 rounded-xxxlg bg-white bg-opacity-80 backdrop-blur-15;

    @screen sm {
        @apply border-none p-0 backdrop-none;
    }

    @screen md {
        @apply border-none p-0 backdrop-none;
    }
}

.navArcadeBorder {
    @apply navBorder rounded-xxxxlg;
    backdrop-filter: unset;
    background-color: unset;
    padding: 0 !important;
    border-width: 0 !important;

    @screen sm {
        @apply border-none p-0 backdrop-none;
    }

    @screen md {
        @apply border-none p-0 backdrop-none;
    }

    .logo {
        width: unset;
    }
    .navContainer {
        @apply rounded-max bg-white-80 shadow-default backdrop-blur-15 p-4;
        @screen sm {
            @apply gap-2;
        }
        @screen md {
            @apply gap-2;
        }
        &ArcadeSmall {
            padding: 14px 1rem;
            & > :first-child {
                flex: 1 1 0;
            }
        }
        &ArcadeMenuActive {
            @apply shadow-none;
            height: 52px;
        }
    }
    .mobileNavigationContainer {
        @apply mr-0;
    }
    .searchInputContainer {
        @screen md {
            @apply order-none flex-none mt-0;
        }

        @screen sm {
            @apply order-none flex-none mt-0;
        }
    }
}

.navContainer {
    @extend %flexBase;
    @apply bg-mint p-4 rounded-xxlg;
    @screen sm {
        @apply flex-wrap justify-between;
    }
    @screen md {
        @apply flex-wrap justify-between;
    }
}

.logo {
    @apply w-46;
    @screen md {
        @apply w-36;
    }
    @screen sm {
        @apply w-28;
    }
}

.logoContainer {
    @extend %flexBase;
    @apply flex-2;
    svg {
        @screen sm {
            @apply w-9/10;
        }
        @screen md {
            @apply w-9/10;
        }
    }

    @screen lg {
        @apply flex-none;
    }
}

.navigationLinksContainer {
    @extend %flexBase;
    @apply justify-between mx-3;
    div > a {
        @apply contentS font-bold -mx-1;
    }

    @screen sm {
        @apply hidden;
    }

    @screen md {
        @apply hidden;
    }
}

.searchCtaContainer {
    @extend %flexBase;
    @extend %flexChildContainerBase;
    @apply flex-row-reverse;
}

.searchInputContainer {
    @extend %flexBase;
    @extend %flexChildContainerBase;

    @apply max-w-vs ml-auto;

    div {
        @apply w-full;
    }

    @screen lg {
        @apply mr-2;
    }

    @screen md {
        @apply max-w-full ml-0 flex-full mt-3 order-3;
    }

    @screen sm {
        @apply max-w-full ml-0 flex-full mt-3 order-3;
    }
}

.searchInputCloseIcon {
    @apply flex items-center justify-center w-5 h-5;
    margin-right: 2px; // for adjustment with menu close button
}

.authDropdownMenu {
    @apply absolute top-14 left-0 right-0;
}

.buttonContainer {
    &:first-of-type {
        @apply mr-2;
    }
    a {
        @apply py-4;
    }
    a:focus {
        @apply border-white outline-none;
    }
    @screen sm {
        @apply order-2;
    }
    @screen md {
        @apply order-2;
    }
    .loginButtonIcon {
        @apply block;
        @screen lg {
            @apply hidden;
        }
    }

    .loginButtonLabel {
        @apply font-bold;
        @screen sm {
            @apply hidden;
        }
        @screen md {
            @apply hidden;
        }
    }
}

.topLevelNavigationContainer {
    @screen sm {
        @apply hidden;
    }
    @screen md {
        @apply hidden;
    }
}

.topLevelNavigationList {
    @apply flex justify-between mt-2 flex-wrap overflow-x-hidden;
}

.topLevelNavigationItem {
    @apply inline-block py-2 font-bold text-xs rounded-xlg whitespace-nowrap cursor-default;
    &Active {
        @apply bg-mint;
    }
}

.subNavigationContainer {
    @apply flex flex-row justify-center;
}

.subNavigation {
    @apply flex flex-col flex-wrap p-2 w-1/6 items-start;
}

.subNavigationItem {
    @apply text-sm rounded-lg cursor-default;
    &Header {
        @apply px-4 py-1;
    }
    a {
        @apply block w-full px-4 py-1;
    }
    &Bold {
        @apply font-semibold;
    }

    &Black {
        @apply text-black;
    }

    &Fire {
        @apply text-fire;
    }

    &Orca {
        @apply text-orca;
    }

    & > a:hover {
        @apply font-bold;
    }
}

.mobileNavigationContainer {
    @apply mr-4;
    @screen lg {
        @apply hidden;
    }
}

.mobileMenuIcon {
    @apply bg-cover bg-no-repeat h-6 w-6;
    background-image: url("../../assets/icons/hamburger-icon.svg");

    &Active {
        background-image: url("../../assets/icons/close-icon.svg");
    }

    &ArcadeActive {
        @apply flex justify-center items-center;
        background-image: none;
    }

    &:hover {
        @apply cursor-pointer;
    }
    @screen sm {
        @apply h-5 w-6;
    }
    @screen md {
        @apply h-5 w-6;
    }
}

.authDropdownMenuButtonContainer {
    .ctaContainer {
        @extend %flexBase;
        @apply justify-end;
    }

    .loggedInContainer {
        @extend %flexBase;
        @extend %flexChildContainerBase;
        @apply bg-white relative h-12 leading-normal outline-none p-2 rounded-xlg text-black transition-all border-transparent border-0-1 cursor-pointer select-none;

        .userInitialsButton {
            @apply contentM bg-mint w-8 h-8 rounded-full justify-center inline-flex  items-center mr-2 font-semibold text-center;
        }
        .userHelloMessage {
            @apply truncate contentM font-semibold;
            @screen sm {
                @apply text-xs;
            }
            @screen md {
                @apply w-34;
            }
            @screen lg {
                @apply w-34;
            }
        }
        .dropdownMenu {
            @apply mr-2 ml-2;
        }
    }
}

.hoverDropdownContainer {
    @apply relative;
}

.linkButtonContainer {
    &:after {
        @apply absolute top-0 left-0;
        content: " ";
        height: 200%;
        width: 100%;
    }
}
