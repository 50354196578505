%mobileNavigationItemChevronBase {
    @apply absolute inline-block right-6;
}

.mobileNavigationContainer {
    @apply invisible fixed duration-500 overflow-y-auto transition-all bottom-0 top-full left-2 right-2;

    &Active {
        @apply visible top-16;
    }
}

.mobileNavigationContent {
    @apply bg-mint rounded-2xl p-4;
}

.mobileNavigationItem {
    @apply py-2;
}

.mobileNavigationItem {
    @apply font-bold flex flex-row items-center justify-between;
    a {
        @apply block w-full;
    }
}

.mobileNavigationChevronContainer {
    @apply h-4 w-12;
}

.mobileNavigationChevron {
    @extend %mobileNavigationItemChevronBase;
    @apply bg-right bg-no-repeat duration-200 h-4 transform transition-all w-4;
    background-image: url("../../assets/icons/chevron-right.svg");
    &Active {
        @apply rotate-90;
    }
}

.mobileNavigationSubMenuContainer {
    @apply px-2;
}

.mobileNavigationSubMenuItem {
    @apply font-light py-2 text-sm;

    &Bold {
        @apply font-bold;
    }

    &Black {
        @apply text-black;
    }

    &Fire {
        @apply text-fire;
    }

    &Orca {
        @apply text-orca;
    }
}

.mobileNavigationFooterContainer {
    @apply pt-6;
}

.footerNavigationItemsContainer {
    @apply flex flex-row flex-wrap mb-12;
}

.footerNavigationItem {
    @apply flex-half headingM py-2;
}

.footerInformationContainer {
    @apply flex flex-row contentXXS;
}

.footerLogo {
    @apply w-10 h-9;
}

.footerServiceContent {
    @apply pl-6;

    a {
        @apply underline text-initial;
    }
}
