.banner {
    @apply flex justify-between bg-fire-enabled rounded-xlg mt-1;
}

.message {
    @apply contentXS text-white px-6 py-1.5;

    @screen sm {
        @apply py-3.5 pl-4;
        padding-right: 12.75px;
    }
}

.iconContainer {
    @apply flex justify-center items-center pr-6;

    @screen sm {
        @apply items-start;
        padding-top: 18.75px;
        padding-right: 16.75px;
    }
}
