%arcadeH1 {
  @apply font-semibold;
  font-size: 4.6875rem;
  line-height: 110%;
}
%arcadeMobileH1 {
  line-height: 110%;
  @apply font-bold;
  font-size: 2.625rem;
}
%arcadeH2 {
  @apply font-semibold;
  font-size: 3.75rem;
  line-height: 110%;
}
%arcadeMobileH2 {
  @apply font-semibold;
  font-size: 3.75rem;
  line-height: 110%;
}
%arcadeH3 {
  @apply font-semibold;
  font-size: 3.125rem;
  line-height: 112%;
}
%arcadeMobileH3 {
  @apply font-semibold;
  font-size: 3.125rem;
  line-height: 112%;
}
%arcadeH4 {
  @apply font-semibold;
  font-size: 2.625rem;
  line-height: 110%;
  @screen sm {
    font-size: 1.875rem;
  }
  @screen md {
    font-size: 1.875rem;
  }
}
%arcadeMobileH4 {
  @apply font-semibold;
  font-size: 1.875rem;
  line-height: 110%;
}
%arcadeH5 {
  @apply font-semibold;
  font-size: 1.875rem;
  line-height: 120%;
}
%arcadeMobileH5 {
  @apply font-semibold;
  font-size: 1.5rem;
  line-height: 115%;
}
%arcadeH6 {
  @apply font-semibold;
  font-size: 1.6875rem;
  line-height: 130%;
}
%arcadeMobileH6 {
  @apply font-semibold;
  font-size: 1.375rem;
  line-height: 115%;
}
%arcadeH7 {
  @apply font-semibold;
  font-size: 1.5rem;
  line-height: 130%;
}
%arcadeMobileH7 {
  @apply font-semibold;
  font-size: 1.375rem;
  line-height: 115%;
}
%arcadeP1 {
  @apply font-normal;
  font-size: 1.5rem;
  line-height: 120%;
}
%arcadeMobileP1 {
  @apply font-normal;
  font-size: 1.5rem;
  line-height: 120%;
}
%arcadeP2 {
  @apply font-normal;
  font-size: 1.3125rem;
  line-height: 130%;
  @screen sm {
    font-size: 1.125rem;
    line-height: 133%;
  }
  @screen md {
    font-size: 1.125rem;
    line-height: 133%;
  }
}
%arcadeMobileP2 {
  @apply font-normal;
  font-size: 1.125rem;
  line-height: 133%;
}
%arcadeP3 {
  @apply font-normal;
  font-size: 1.125rem;
  line-height: 130%;
}
%arcadeMobileP3 {
  @apply font-normal;
  font-size: 1.125rem;
  line-height: 130%;
}
%arcadeCTA1 {
  @apply font-semibold;
  font-size: 1.3125rem;
  line-height: 100%;
}
%arcadeMobileCTA1 {
  @apply font-semibold;
  font-size: 1.3125rem;
  line-height: 100%;
}
%arcadeCTA2 {
  @apply font-semibold;
  font-size: 1rem;
  line-height: 100%;
}
%arcadeMobileCTA2 {
  @apply font-bold;
  font-size: 1rem;
  line-height: 100%;
}
%arcadeFooter1 {
  @apply font-semibold;
  font-size: 1.3125rem;
  line-height: 120%;
}
%arcadeMobileFooter1 {
  @apply font-bold;
  font-size: 1.25rem;
  line-height: 100%;
}
%arcadeFooter2 {
  @apply font-normal;
  font-size: 0.875rem;
  line-height: 130%;
}
%arcadeMobileFooter2 {
  @apply font-normal;
  font-size: 0.875rem;
  line-height: 155%;
}
%arcadeNav {
  @apply font-normal;
  font-size: 1rem;
  line-height: 130%;
  @screen sm {
    line-height: 100%;
  }
  @screen md {
    line-height: 100%;
  }
}
%arcadeMobileNav {
  @apply font-normal;
  font-size: 1rem;
  line-height: 100%;
}
%arcadeSmall {
  @apply font-normal;
  font-size: 0.75rem;
  line-height: 130%;
  @screen sm {
    line-height: 120%;
  }
  @screen md {
    line-height: 120%;
  }
}
%arcadeMobileSmall {
  @apply font-normal;
  font-size: 0.75rem;
  line-height: 120%;
}
