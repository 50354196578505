.container {
  @apply flex justify-start items-center gap-3;
}

.globeIcon {
  @apply w-5 h-5;
  background-image: url('../../../assets/icons/arcade-globe.svg');
  background-size: cover;
}

.label {
  @apply font-bold text-black text-md;
}