%mobileNavigationItemChevronBase {
    @apply absolute inline-block right-6;
}

.mobileNavigationContainerArcade {
    @apply invisible absolute top-0 -left-2 w-screen h-screen bg-white;

    &Active {
        @apply visible;
    }
}

.mobileNavigationContentArcade {
    @apply bg-white rounded-2xl px-8 py-0;
    .mobileNavigationItem > a {
        @apply font-semibold;
    }
}

.mobileNavigationItem {
    @apply py-2;
}

.mobileNavigationItem {
    @apply font-bold flex flex-row items-center justify-between;
    a {
        @apply block w-full;
    }
}

.mobileNavigationChevronContainer {
    @apply h-4 w-12;
}

.mobileNavigationChevron {
    @extend %mobileNavigationItemChevronBase;
    @apply bg-right bg-no-repeat duration-200 h-4 transform transition-all w-4;
    background-image: url("../../../../assets/icons/chevron-right.svg");
    &Active {
        @apply rotate-90;
    }
}

.mobileNavigationSubMenuContainer {
    @apply px-2;
}

.mobileNavigationSubMenuItem {
    @apply font-light py-2 text-sm;

    &Bold {
        @apply font-bold;
    }

    &Black {
        @apply text-black;
    }

    &Fire {
        @apply text-fire;
    }

    &Orca {
        @apply text-orca;
    }
}

.mobileNavigationFooterContainer {
    @apply pt-6;
}

.footerNavigationItemsContainer {
    @apply flex flex-row flex-wrap mb-12;
}

.footerNavigationItem {
    @apply flex-half headingM py-2;
}

.footerInformationContainer {
    @apply flex flex-row contentXXS;
}

.footerLogo {
    @apply w-10 h-9;
}

.footerServiceContent {
    @apply pl-6;

    a {
        @apply underline text-initial;
    }
}

.mobileNavigationBarArcade {
    @apply mt-2 ml-4 mr-2;
    height: 2.25rem;
    padding: 2.125rem 1rem;
    @apply flex items-center;

    .mobileNavigationBarLogoArcade {
        @apply flex-2;
        svg {
            @screen sm {
                @apply w-9/10;
            }
            @screen md {
                @apply w-9/10;
            }
        }
    }
}

.mobileNavigationCloseIconArcade {
    @apply bg-cover bg-no-repeat h-9 w-9;
    @apply flex justify-center items-center;
}