.container {
  display: block;
}

.logoSmall {
  width: rem(86.4px);
  height: rem(16px);
}

.logoMedium {
  width: rem(108px);
  height: rem(20px);
}

.logoLarge {
  width: rem(144px);
  height: rem(28.5px);
}

.logoXLarge {
  width: rem(184px);
  height: rem(36.5px);
}

.themeUnicorn {
  &.logoSmall {
    width: rem(65.3px);
    height: rem(16px);
  }

  &.logoMedium {
    width: rem(98px);
    height: rem(24px);
  }

  &.logoLarge {
    width: rem(114px);
    height: rem(28px);
  }

  &.logoXLarge {
    width: rem(147px);
    height: rem(36px);
  }
}